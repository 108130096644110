import React, { Suspense, useEffect, useState } from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components/macro'
import GoogleAnalyticsReporter from '../components/analytics/GoogleAnalyticsReporter'
// import AddressClaimModal from '../components/claim/AddressClaimModal'
import Header from '../components/Header'
// import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import Web3ReactManager from '../components/Web3ReactManager'
import ErrorBoundary from '../components/ErrorBoundary'
// import { ApplicationModal } from '../state/application/actions'
// import { useModalOpen, useToggleModal } from '../state/application/hooks'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
// import { RedirectDuplicateTokenIds } from './AddLiquidity/redirects'
// import Earn from './Earn'
// import Manage from './Earn/Manage'
// import MigrateV2 from './MigrateV2'
// import MigrateV2Pair from './MigrateV2/MigrateV2Pair'
// import Pool from './Pool'
// import PoolV2 from './Pool/v2'
// import PoolFinder from './PoolFinder'
// import RemoveLiquidity from './RemoveLiquidity'
// import RemoveLiquidityV3 from './RemoveLiquidity/V3'
import Swap from './Swap'
import { RedirectPathToSwapOnly, RedirectToSwap } from './Swap/redirects'
// import Vote from './Vote'
// import VotePage from './Vote/VotePage'
// import { RedirectDuplicateTokenIdsV2 } from './AddLiquidityV2/redirects'
// import { PositionPage } from './Pool/PositionPage'
// import AddLiquidity from './AddLiquidity'
import YamProvider from 'contexts/YamProvider'
import ModalsProvider from 'contexts/Modals'
import NFTSwap from './NFTSwap'
import Launchpad from './Launchpad'
import { ThemedBackground } from '../theme'
import ApeModeQueryParamReader from 'hooks/useApeModeQueryParamReader'
import { MEDIA_WIDTHS } from '../theme'
import TopBakery from '../components/TopBakery'
// import { useWeb3React } from '@web3-react/core'

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
`

const TopWrapper = styled.div`
  width: 100%;
  justify-content: space-between;
`

const ContentWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: space-between;
  flex: 1 1 auto;
`
const FoldHeader = 'ACTIVENAV'

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  transition: width 0.4s cubic-bezier(0.17, 0.8, 0.33, 0.99);
  width: 248px;
  &.${FoldHeader} {
    width: 60px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 0px;
    `};
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    position: absolute;
    z-index:99;
  `};
`
const BodyContainer = styled.div`
  width: calc(100% - 248px);
  height: calc(100vh - 62px);
  overflow: auto;
  &.${FoldHeader} {
    width: calc(100% - 60px);
    ${({ theme }) => theme.mediaWidth.upToMedium`
      width: 100%;
    `};
  }
  ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
  `};
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  z-index: 10;
  /* ${({ theme }) => theme.mediaWidth.upToExtraSmall`
    padding: 16px;
  `}; */

  z-index: 1;
`

// const Marginer = styled.div`
//   margin-top: 5rem;
// `

// function TopLevelModals() {
//   const open = useModalOpen(ApplicationModal.ADDRESS_CLAIM)
//   const toggle = useToggleModal(ApplicationModal.ADDRESS_CLAIM)
//   return <AddressClaimModal isOpen={open} onDismiss={toggle} />
// }
const Providers: React.FC = ({ children }) => {
  return (
    <YamProvider>
      <ModalsProvider>{children}</ModalsProvider>
    </YamProvider>
  )
}

export default function App() {
  const [isFold, setIsFold] = useState(false)
  // const { chainId } = useWeb3React()
  const triggerFold = (fold: boolean) => {
    setIsFold(fold)
    doResize()
  }
  const doResize = () => {
    setTimeout(() => {
      if (document.createEvent) {
        const event = document.createEvent('HTMLEvents')
        event.initEvent('resize', true, true)
        window.dispatchEvent(event)
      }
    }, 230)
  }

  // 获取当前屏幕宽
  const getScreenWidth = () => {
    if (document.body.offsetWidth < +MEDIA_WIDTHS.upToMedium) {
      triggerFold(true)
    } else {
      triggerFold(false)
    }
  }

  useEffect(() => {
    getScreenWidth()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ErrorBoundary>
      <Suspense fallback={null}>
        <Providers>
          <Route component={GoogleAnalyticsReporter} />
          <Route component={DarkModeQueryParamReader} />
          <Route component={ApeModeQueryParamReader} />
          <AppWrapper>
            <TopWrapper>
              <TopBakery triggerFold={triggerFold} isFold={isFold} />
            </TopWrapper>
            <ContentWrapper>
              <HeaderWrapper className={isFold ? FoldHeader : ''}>
                <Header triggerFold={triggerFold} isFold={isFold} />
              </HeaderWrapper>
              <BodyContainer className={isFold ? FoldHeader : ''} id="BodyContainer">
                <BodyWrapper>
                  <ThemedBackground />
                  <Popups />
                  {/* <Polling /> */}
                  {/* <TopLevelModals /> */}
                  <Web3ReactManager>
                    <Switch>
                      {/* <Route exact strict path="/vote" component={Vote} />
                    <Route exact strict path="/vote/:id" component={VotePage} />
                    <Route exact strict path="/claim" component={OpenClaimAddressModalAndRedirectToSwap} />
                    <Route exact strict path="/uni" component={Earn} />
                    <Route exact strict path="/uni/:currencyIdA/:currencyIdB" component={Manage} />

                    <Route exact strict path="/send" component={RedirectPathToSwapOnly} /> */}
                      <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} />
                      <Route exact strict path="/swap" component={Swap} />

                      {/* <Route exact strict path="/find" component={PoolFinder} />
                    <Route exact strict path="/pool/v2" component={PoolV2} />
                    <Route exact strict path="/pool" component={Pool} />
                    <Route exact strict path="/pool/:tokenId" component={PositionPage} />

                    <Route
                      exact
                      strict
                      path="/add/v2/:currencyIdA?/:currencyIdB?"
                      component={RedirectDuplicateTokenIdsV2}
                    />
                    <Route
                      exact
                      strict
                      path="/add/:currencyIdA?/:currencyIdB?/:feeAmount?"
                      component={RedirectDuplicateTokenIds}
                    />

                    <Route
                      exact
                      strict
                      path="/increase/:currencyIdA?/:currencyIdB?/:feeAmount?/:tokenId?"
                      component={AddLiquidity}
                    />

                    <Route exact strict path="/remove/v2/:currencyIdA/:currencyIdB" component={RemoveLiquidity} />
                    <Route exact strict path="/remove/:tokenId" component={RemoveLiquidityV3} />

                    <Route exact strict path="/migrate/v2" component={MigrateV2} />
                    <Route exact strict path="/migrate/v2/:address" component={MigrateV2Pair} /> */}
                      <Route exact strict path="/nftswap" component={NFTSwap} />
                      <Route exact strict path="/launchpad" component={Launchpad} />
                      <Route component={RedirectPathToSwapOnly} />
                    </Switch>
                  </Web3ReactManager>
                  {/* <Marginer /> */}
                </BodyWrapper>
              </BodyContainer>
            </ContentWrapper>
          </AppWrapper>
        </Providers>
      </Suspense>
    </ErrorBoundary>
  )
}
