import { useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AppState } from 'state'
import { updateSolanaPublicKey, updateSolanaWallet } from './actions'

export function useSolanaPublicKey(): AppState['solana']['solanaPublicKey'] {
  return useSelector((state: AppState) => state.solana.solanaPublicKey)
}
export function useUpdateSolanaPublicKey(): (solanaPublicKey: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (solanaPublicKey: string) => {
      dispatch(updateSolanaPublicKey({ solanaPublicKey }))
    },
    [dispatch]
  )
}
export function useSolanaWallet(): AppState['solana']['solanaWallet'] {
  return useSelector((state: AppState) => state.solana.solanaWallet)
}
export function useUpdateSolanaWallet(): (solanaWallet: string) => void {
  const dispatch = useDispatch()

  return useCallback(
    (solanaWallet: string) => {
      dispatch(updateSolanaWallet({ solanaWallet }))
    },
    [dispatch]
  )
}
