import React, { useContext, useState, useCallback, useEffect, useRef } from 'react'
import styled, { ThemeContext } from 'styled-components'

// components
import Row, { AutoRow, RowCenter } from 'components/Row'
import ExchangePets from './ExchangePets'
import { ButtonConfirmed } from 'components/Button'
import Loader from 'components/Loader'
// import WhitelistModal from './WhitelistModal'
// import CheckWL from './CheckWL'

import BigNumber from 'bignumber.js'

// hooks
import { useActiveWeb3React } from 'hooks'
import { useAddPopup, useWalletModalToggle } from 'state/application/hooks'
import { useTokenBalance } from 'state/wallet'
import idoLaunch from 'hooks/ido/idoLaunch'
// import useHandleIdo from 'hooks/ido/useHandleIdo'
import useApproveLaunch from 'hooks/ido/useApproveLaunch'
import useAllowanceLaunch from 'hooks/ido/useAllowanceLaunch'
import useAllowanceNum from 'hooks/ido/useAllowanceNum'
import useAllowanceSmallNum from 'hooks/ido/useAllowanceSmallNum'
import useAllowanceMax from 'hooks/ido/useAllowanceMax'

import { useLocation } from 'react-router-dom'
import { IDOTYPE } from 'constants/ido'
// import useModal from 'hooks/bakery/useModal'
import { FnContext } from 'contexts/fnCallback'

import { getWeb3 } from 'utils/web3'
import { getWhitelist } from 'service/launchpad'
import {
  NETWORK_LABELS,
  NETWORKk_RPC_URL,
  NETWORKk_URL_MAP,
  TO_DEV_CHAINID,
  TO_MASTER_CHAINID,
  SUPPORTID,
  IDTOKEN,
  BAKERY_HOST_ORG,
} from 'constants/pet'
import { StorageEmitter, formatTime, getAccountToken } from 'utils/tools'
import TipIcon from 'assets/svg/whitelist_error.svg'
import { useGetUserInfo, useLogin, useLoginTwitter } from 'hooks/useLogin'
import { useUserInfo } from 'state/twitter/hooks'
import { isMobile } from 'react-device-detect'
import { TYPE } from 'theme'
// import InvitationCode from './InvitationCode'

const Exchange = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  margin: 0 0 32px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${({ theme }) => theme.bsBg45};
  letter-spacing: 0.01em;

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 24px 0 20px;
    padding-bottom: 30px;
  `}
`
export const BtnConfirmed = styled.button`
  width: 100%;
  height: 54px;
  margin: 19px auto 0;
  padding: 0;
  border: none;
  border-radius: 12px;
  font-family: Baloo Da, 'Inter var', sans-serif;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  background-color: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#AC552A')};
  color: ${({ theme }) => (theme.darkMode ? '#722F0D' : '#fff')};
  cursor: pointer;
  outline: none;

  &:disabled {
    cursor: auto;
    opacity: 0.4;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 20px auto 0;
    height: 53px;
    font-size: 16px;
  `}
`
const Des = styled.div`
  font-family: 'Roboto-Regular', 'Inter var', sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.01em;
  white-space: pre-line;
  color: ${({ theme }) => (theme.darkMode ? '#776D63' : '#C58560')};

  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 14px;
    word-break: break-word;
  `}
`

export const Summary = styled.div`
  display: flex;
  margin: 10px 0 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: ${({ theme }) => theme.text6};

  img {
    margin: -2px 7px 0 0;
    width: 18px;
    height: 18px;
  }
  ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 12px;
  `}
`

const Border = styled.div`
  margin-top: 32px;
  border-bottom: 1px solid ${({ theme }) => theme.bsBg45};
`

const StepWrapper = styled.div`
  background: ${({ theme }) => (theme.darkMode ? '#39302C' : '#fffbef')};
  width: 100%;
  padding: 20px 24px 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border-radius: 10px;
  margin-top: 32px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 17px 16px;
  `}
`

const Title = styled.div`
  font-family: 'Roboto-Medium';
  font-size: 14px;
  margin-bottom: 8px;
  color: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#ac552a')};
  line-height: 133%;
  span {
    color: #722f0d;
    font-family: 'Roboto-Bold';
  }
`

const Desc = styled.div`
  font-family: 'Roboto-Regular';
  font-size: 12px;
  line-height: 14px;
  color: ${({ theme }) => (theme.darkMode ? '#C58560' : '#c58560cc')};
`

const FollowBtn = styled.button`
  border: none;
  padding: 0;
  width: 122px;
  height: 32px;
  border-radius: 5px;
  background: ${({ theme }) => (theme.darkMode ? '#FFCD84' : '#ac552a')};
  font-family: Baloo Da;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => (theme.darkMode ? '#722F0D' : '#fff')};
  cursor: pointer;
`

const TwitterName = styled.div`
  font-family: 'Roboto-Bold';
  font-size: 14px;
  line-height: 16px;
  color: #ac552a;
  a {
    color: #ac552a;
  }
`

function useQuery() {
  return new URLSearchParams(useLocation().search)
}

interface IdoSupportProps {
  type: any
  options: any
  dataInfo: any
  defaultSymbol?: any
  platform?: string
  brc20?: any
}

export default function SupportCard({
  type,
  options,
  dataInfo,
  defaultSymbol,
  platform = 'bitcoincats',
}: IdoSupportProps) {
  const { account, chainId, library } = useActiveWeb3React()
  const [, loginFn] = useLogin()
  const [, getUserInfoFn] = useGetUserInfo()
  const userInfo = useUserInfo()
  const addPopup = useAddPopup()
  const toggleWalletModal = useWalletModalToggle()
  const theme = useContext(ThemeContext)
  const callback: any = useContext(FnContext)

  const [baseAsset, setBaseAsset] = useState(defaultSymbol ? defaultSymbol : options[0])
  const [value, setValue] = useState<string | number>('')

  // allowance
  const updatePetAmount = () => {}

  const allowanceLaunch = useAllowanceLaunch(baseAsset.symbol.toLowerCase(), type)
    .div(new BigNumber(10).pow(18))
    ?.toNumber()

  const allowanceNum = new BigNumber(useAllowanceNum(baseAsset.symbol.toLowerCase(), type))
    .div(new BigNumber(10).pow(18))
    .toString()

  const allowanceSmallNum = new BigNumber(useAllowanceSmallNum(baseAsset.symbol.toLowerCase(), type))
    .div(new BigNumber(10).pow(18))
    .toString()

  const allowanceMax = new BigNumber(useAllowanceMax(baseAsset.symbol.toLowerCase(), type))
    .div(new BigNumber(10).pow(18))
    .toString()

  const query = useQuery()
  useEffect(() => {
    try {
      const status = query.get('status')
      if (status) {
        const msg: any = query.get('message')
        addPopup({
          txn: {
            hash: '',
            success: status === 'true' ? true : false,
            summary: status === 'true' ? 'Authorization complete' : msg ? msg : 'Authorization failed',
          },
        })
        window.location.href = window.location.origin + '/#/launchpad'
      }
    } catch (e) {
      console.log('Oauth feedback', e)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // console.log('========', allowanceMax, allowanceSmallNum, allowanceNum)
  const updateValue = useCallback((value) => {
    // if (baseAsset.symbol.toLowerCase() === 'eth' && +value > +allowanceNum) {
    //   value = allowanceNum
    // }

    // if (baseAsset.symbol.toLowerCase() === 'eth' && +value < 0.01 && +value > 0) {
    //   value = 0.01
    // }
    setValue(value)
  }, [])

  const updateBaseAsset = useCallback(
    async (value: any) => {
      if (!account) {
        setBaseAsset(value)
        callback(value)
        updateValue('')
        updatePetAmount()
        return
      }
      if (
        (type === IDOTYPE.ONECAT &&
          ['bakery', 'eth', 'wbtc', 'doggycoin', 'punkx'].includes(value.symbol.toLowerCase())) ||
        ([
          IDOTYPE.BITLAND,
          IDOTYPE.BITCAT,
          IDOTYPE.BENDDAO,
          IDOTYPE.CATNFT,
          IDOTYPE.OPENSKY,
          IDOTYPE.BITCOINVM,
        ].includes(type) &&
          ['eth', 'wbtc', 'bakery', 'onecat'].includes(value.symbol.toLowerCase()))
      ) {
        const toChainId = BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
        const ethereum: any = library?.provider
        // console.log('d.symbol', value.symbol)
        if (chainId === toChainId[value.symbol.toLowerCase()]) {
          setBaseAsset(value)
          callback(value)
          updateValue('')
          updatePetAmount()
          return
        }

        const web3 = getWeb3(toChainId[value.symbol.toLowerCase()])
        await ethereum
          .request({
            method: 'wallet_switchEthereumChain',
            params: [
              {
                chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
              },
            ],
          })
          .then(() => {
            setBaseAsset(value)
            callback(value)
            updateValue('')
            updatePetAmount()
            // window.location.reload()
          })
          .catch(async (e: any) => {
            if ((e as any).code === 4902 || (e as any).code === -32603) {
              ethereum
                .request({
                  method: 'wallet_addEthereumChain',
                  params: [
                    {
                      chainId: web3.utils.toHex(toChainId[value.symbol.toLowerCase()]),
                      chainName: NETWORK_LABELS[toChainId[value.symbol.toLowerCase()]],
                      nativeCurrency: {
                        name: 'ETH',
                        symbol: 'ETH',
                        decimals: 18,
                      },
                      rpcUrls: [NETWORKk_RPC_URL[toChainId[value.symbol.toLowerCase()]]],
                      blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[value.symbol.toLowerCase()]]],
                    },
                  ],
                })
                .then(() => {
                  setBaseAsset(value)
                  callback(value)
                  updateValue('')
                  updatePetAmount()
                  // window.location.reload()
                })
                .catch((e: any) => {
                  console.log(JSON.stringify(e))
                })
            } else if ((e as any).code === 4001) return
          })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [account, type, callback, updateValue, library?.provider, chainId]
  )

  const [requestedApprovalLaunch, setRequestedApprovalLaunch] = useState(false)
  const onApproveLaunch = useApproveLaunch(
    baseAsset.symbol.toLowerCase(),
    baseAsset.symbol.toLowerCase() === 'onecat' ? IDOTYPE.ONECAT : type
  )
  const [isShowTip, setIsShowTip] = useState(false)
  const [isWL, setIsWL] = useState(false)
  const [nonWhitelistEndTime, setNonWhitelistEndTime] = useState(0)
  const handleApproveLaunch = useCallback(async () => {
    if (!SUPPORTID.includes(chainId as number)) {
      updateBaseAsset({
        symbol: 'ETH',
        name: 'eth',
        title: 'ETH',
        status: 0,
      })
      return
    }
    try {
      setRequestedApprovalLaunch(true)
      const txHash = await onApproveLaunch()
      if (!txHash) {
        setRequestedApprovalLaunch(false)
      } else {
        setTimeout(() => {
          setRequestedApprovalLaunch(false)
        }, 1000)
      }
    } catch (e) {
      console.log(e)
      setRequestedApprovalLaunch(false)
    }
  }, [chainId, onApproveLaunch, updateBaseAsset])

  // Get token balance
  const balance = useTokenBalance(baseAsset.symbol.toLowerCase())

  // buy
  const { onIdo } = idoLaunch(baseAsset.symbol.toLowerCase(), type)
  const [isETHShowTip, setIsETHShowTip] = useState(false)
  const [isNonWLShowTip, setIsNonWLShowTip] = useState(false)
  // const [isBrc20ShowTip, setIsBrc20ShowTip] = useState(false)
  const [requestedIdo, setRequestedIdo] = useState(false)
  const [isLogin, setIsLogin] = useState(false)
  const handleIdo = useCallback(async () => {
    setIsShowTip(false)
    setIsNonWLShowTip(false)
    setIsETHShowTip(false)
    // setIsBrc20ShowTip(false)
    // if ([IDOTYPE.BITCAT, IDOTYPE.BENDDAO, IDOTYPE.CATNFT].includes(type) && !brc20) {
    //   setIsBrc20ShowTip(true)
    //   return
    // }
    if ([IDOTYPE.BITLAND, IDOTYPE.ONECAT, IDOTYPE.CATNFT].includes(type) && !isWL && Date.now() < nonWhitelistEndTime) {
      setIsShowTip(true)
      return
    }
    if ([IDOTYPE.CATNFT].includes(type) && !isWL && baseAsset.symbol.toLowerCase() === 'eth') {
      setIsNonWLShowTip(true)
      return
    }

    if (
      [IDOTYPE.CATNFT].includes(type) &&
      isWL &&
      baseAsset.symbol.toLowerCase() !== 'eth' &&
      Date.now() < nonWhitelistEndTime
    ) {
      setIsETHShowTip(true)
      return
    }
    if (
      [
        IDOTYPE.ONECAT,
        IDOTYPE.BITLAND,
        IDOTYPE.BITCAT,
        IDOTYPE.BENDDAO,
        IDOTYPE.CATNFT,
        IDOTYPE.OPENSKY,
        IDOTYPE.BITCOINVM,
        IDOTYPE.IQ50,
        IDOTYPE.DOGGY,
        IDOTYPE.BAKERY,
      ].includes(type as any)
    ) {
      setRequestedIdo(true)
      try {
        const res = await onIdo(account, value)
        console.log('------res-----', res)
        setRequestedIdo(false)
      } catch (e) {
        console.log('handleIdo', e)
        setRequestedIdo(false)
      }
    }
  }, [type, isWL, nonWhitelistEndTime, baseAsset.symbol, account, onIdo, value])

  const switchNetwork = useCallback(async () => {
    const toChainId = !BAKERY_HOST_ORG ? TO_MASTER_CHAINID : TO_DEV_CHAINID
    const ethereum: any = library?.provider
    if (chainId === toChainId[baseAsset.symbol.toLowerCase()]) return
    const web3 = getWeb3(toChainId[baseAsset.symbol.toLowerCase()])
    await ethereum
      .request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
          },
        ],
      })
      .then(() => {
        // window.location.reload()
      })
      .catch(async (e: any) => {
        if ((e as any).code === 4902 || (e as any).code === -32603) {
          ethereum
            .request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: web3.utils.toHex(toChainId[baseAsset.symbol.toLowerCase()]),
                  chainName: NETWORK_LABELS[toChainId[baseAsset.symbol.toLowerCase()]],
                  nativeCurrency: {
                    name: 'ETH',
                    symbol: 'ETH',
                    decimals: 18,
                  },
                  rpcUrls: [NETWORKk_RPC_URL[toChainId[baseAsset.symbol.toLowerCase()]]],
                  blockExplorerUrls: [NETWORKk_URL_MAP[toChainId[baseAsset.symbol.toLowerCase()]]],
                },
              ],
            })
            .then(() => {})
            .catch((e: any) => {
              console.log(JSON.stringify(e))
            })
        } else if ((e as any).code === 4001) return
      })
  }, [baseAsset.symbol, chainId, library?.provider])

  useEffect(() => {
    handleFetchTime()
    const token = getAccountToken()
    switchNetwork()
    if (token) {
      setIsLogin(true)
      getUserInfoFn()
    } else {
      setIsLogin(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId])

  useEffect(() => {
    StorageEmitter.on('storageSetItem', () => {
      const token = getAccountToken()
      if (token) {
        setIsLogin(true)
        getUserInfoFn()
        handleFetchTime()
      }
    })
    return () => {
      StorageEmitter.off('storageSetItem', () => {
        console.log('off')
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isLess = useCallback(() => {
    if (baseAsset?.min && +value < baseAsset?.min) {
      return true
    } else {
      if (
        // new BigNumber(allowanceNum).comparedTo(new BigNumber(value)) === -1 ||
        new BigNumber(allowanceSmallNum).comparedTo(new BigNumber(value)) === 1 &&
        new BigNumber(allowanceNum).comparedTo(new BigNumber(allowanceMax)) === 0
      ) {
        return true
      } else {
        return false
      }
    }
  }, [baseAsset?.min, value, allowanceNum, allowanceSmallNum, allowanceMax])

  // const [recipient, setRecipient] = useState('')
  // const onChangeRecipient = useCallback((value: string) => {
  //   setRecipient(value)
  // }, [])

  useEffect(() => {
    if (account) {
      if (type === IDOTYPE.ONECAT && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (
          IDTOKEN['eth'].includes(chainId as number) &&
          !['eth', 'doggycoin'].includes(baseAsset.symbol.toLowerCase())
        ) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        if (IDTOKEN['punkx'].includes(chainId as number) && !['punkx'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[3]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if (
        [IDOTYPE.BITLAND, IDOTYPE.BITCAT, IDOTYPE.BENDDAO, IDOTYPE.OPENSKY, IDOTYPE.BITCOINVM].includes(type) &&
        SUPPORTID.includes(chainId as number)
      ) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['onecat'].includes(chainId as number) && !['onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
      if ([IDOTYPE.CATNFT].includes(type) && SUPPORTID.includes(chainId as number)) {
        let resetBaseAsset: any = baseAsset
        if (IDTOKEN['eth'].includes(chainId as number) && !['eth', 'onecat'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[0]
        }
        if (IDTOKEN['bakery'].includes(chainId as number) && !['bakery'].includes(baseAsset.symbol.toLowerCase())) {
          resetBaseAsset = options[2]
        }
        setBaseAsset(resetBaseAsset)
        callback(resetBaseAsset)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, account, type, callback, options])

  const [leftTime, setLeftTime] = useState('')
  const [startTime, setStartTime] = useState(0)
  const [whitelistEndTime, setWhitelistEndTime] = useState(0)
  const [checkIsEnd, setCheckIsEnd] = useState(false)

  const handleFetchTime = useCallback(async () => {
    if (!account) return
    const { data: res } = await getWhitelist(chainId as number, { address: account, platform: platform })
    if (res.code === 0 && res.data?.config) {
      setIsWL(res.data.isWhitelist)
      setCheckIsEnd(res.data?.config?.timeEnd < Date.now())
      const {
        timeOn: { whitelist, nonWhitelist },
      } = res.data.config
      if (startTime !== whitelist) {
        setStartTime(whitelist)
        setWhitelistEndTime(whitelist)
        setNonWhitelistEndTime(nonWhitelist)
      }
    }
  }, [account, chainId, platform, startTime])

  const cdRef: any = useRef(null)
  useEffect(() => {
    const updateCountDown = () => {
      if (startTime) {
        const leftTime = (startTime - Date.now()) / 1000
        setWhitelistEndTime(leftTime)
        if (leftTime > 0) {
          const t = formatTime(leftTime)
          setLeftTime(t)
        } else {
          setLeftTime('')
          clearInterval(cdRef.current)
        }
      } else {
        clearInterval(cdRef.current)
      }
    }

    cdRef.current = setInterval(updateCountDown, 1000)

    return () => {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [startTime])

  useEffect(() => {
    if (whitelistEndTime < 0) {
      clearInterval(cdRef.current)
      cdRef.current = null
    }
  }, [whitelistEndTime])

  const oauthTwitterFn = useLoginTwitter()
  const [isTwitterLoading, setIsTwitterLoading] = useState(false)
  const handleTwitterAuth = useCallback(() => {
    if (!account) {
      toggleWalletModal()
      return
    }
    if (!isLogin) {
      loginFn()
      return
    }
    if (isTwitterLoading || userInfo?.twitterId) return
    setIsTwitterLoading(true)
    oauthTwitterFn()
    setIsTwitterLoading(false)
  }, [account, isLogin, isTwitterLoading, userInfo, oauthTwitterFn, toggleWalletModal, loginFn])

  return (
    <div>
      <Exchange>
        <ExchangePets
          options={options}
          baseAsset={baseAsset}
          updateBaseAsset={updateBaseAsset}
          balance={balance}
          value={value}
          updateValue={updateValue}
          petShow={false}
          allowanceNum={allowanceNum}
          idoMax={
            [
              IDOTYPE.ONECAT,
              IDOTYPE.BITLAND,
              IDOTYPE.BITCAT,
              IDOTYPE.BENDDAO,
              IDOTYPE.CATNFT,
              IDOTYPE.OPENSKY,
              IDOTYPE.BITCOINVM,
            ].includes(type as any)
              ? true
              : false
          }
          idoMin={false}
          // type={type}
        />
        {!account ? (
          <BtnConfirmed onClick={toggleWalletModal}>Connect Wallet</BtnConfirmed>
        ) : baseAsset.symbol.toLowerCase() === 'eth' || (allowanceLaunch && +allowanceLaunch >= +value) ? (
          <BtnConfirmed
            onClick={handleIdo}
            disabled={!(value && +value > 0) || balance.lt(value) || requestedIdo || isLess() || checkIsEnd}
            style={!startTime && whitelistEndTime >= 0 ? { cursor: 'auto', opacity: 0.4 } : {}}
          >
            {!startTime && whitelistEndTime >= 0 ? (
              <>Countdown {leftTime}</>
            ) : checkIsEnd ? (
              'Finished'
            ) : requestedIdo ? (
              <AutoRow gap="6px" justify="center">
                Pending <Loader stroke={theme.darkMode ? '#722F0D' : 'white'} />
              </AutoRow>
            ) : balance.lt(value) ? (
              'Insufficient Balance'
            ) : (
              'Participate'
            )}
          </BtnConfirmed>
        ) : (
          <BtnConfirmed
            onClick={() => handleApproveLaunch()}
            disabled={requestedApprovalLaunch || checkIsEnd || balance.toNumber() <= 0}
          >
            {!isLogin ? (
              'Login'
            ) : requestedApprovalLaunch ? (
              <AutoRow gap="6px" justify="center">
                Approving <Loader stroke="white" />
              </AutoRow>
            ) : (
              'Approve'
            )}
          </BtnConfirmed>
        )}
        {isShowTip && nonWhitelistEndTime - Date.now() > 0 && (
          <Summary>
            <img src={TipIcon} />{' '}
            <span>
              {`This address is not on the Whitelist. The Non-WL Rounds start 1 hour later. Please be patient :)`}
            </span>
          </Summary>
        )}
        {isETHShowTip && nonWhitelistEndTime - Date.now() > 0 && (
          <Summary>
            <img src={TipIcon} /> <span>{` You can only use $ETH to participate in the WL Round :)`}</span>
          </Summary>
        )}
        {isNonWLShowTip && (
          <Summary>
            <img src={TipIcon} /> <span>{` You can only use $Bake or $1Cat to participate :)`}</span>
          </Summary>
        )}
        {/* {isBrc20ShowTip && (
          <Summary>
            <img src={TipIcon} /> <span>{` You MUST submit BRC20 Address before participate :)`}</span>
          </Summary>
        )} */}
        {/* <Border />
        <StepWrapper>
          <Title>Connect your Twitter and follow @bakery_swap</Title>
          <Desc>
            &nbsp;You will have a chance to receive an airdrop of 50,000 tokens! If your account is Twitter Blue, your
            chances of winning are significantly higher.
          </Desc>
          {!userInfo?.twitterId ? (
            <Row marginTop="24px">
              <FollowBtn
                onClick={handleTwitterAuth}
                disabled={isTwitterLoading}
                style={userInfo?.twitterId ? { cursor: 'auto' } : {}}
              >
                {isTwitterLoading ? (
                  <RowCenter>
                    <TYPE.body fontSize={isMobile ? 12 : 18} marginRight={'5px'}>
                      Loading
                    </TYPE.body>
                    <Loader stroke="white" />
                  </RowCenter>
                ) : (
                  'Follow'
                )}
              </FollowBtn>
            </Row>
          ) : userInfo.twitterInfo.verified ? (
            <div style={{ marginTop: '24px' }}>
              <TwitterName>
                Twitter ID:{' '}
                <a href={`https://twitter.com/${userInfo.twitterInfo.username}`} target="_blank" rel="noreferrer">
                  {userInfo.twitterInfo.username}
                </a>
              </TwitterName>
              <Row marginTop="8px" align="center">
                <img style={{ width: 27, height: 27, marginRight: 5 }} src="/images/launchpad/twitter_icon.png" />
                <TwitterName>3M $??? will be airdropped to your address</TwitterName>
              </Row>
            </div>
          ) : (
            <div style={{ marginTop: '24px' }}>
              <TwitterName>
                Twitter ID:{' '}
                <a href={`https://twitter.com/${userInfo.twitterInfo.username}`} target="_blank" rel="noreferrer">
                  {userInfo.twitterInfo.username}
                </a>
              </TwitterName>
              <Row marginTop="8px">
                <TwitterName>You do not have Twitter Blue</TwitterName>
              </Row>
            </div>
          )}
        </StepWrapper> */}
      </Exchange>
      {/* {![IDOTYPE.BENDDAO].includes(type) && (
        <CheckWL value={recipient} platform={platform} onChange={onChangeRecipient}></CheckWL>
      )} */}
      {/* <InvitationCode value={recipient} onChange={onChangeRecipient}></InvitationCode> */}
      <Des color={theme.bsText2}>{dataInfo.des}</Des>
      {dataInfo.illustration && (
        <img style={{ width: '100%', height: 'auto', marginTop: '20px' }} src={dataInfo.illustration} alt="" />
      )}
    </div>
  )
}
