import { useCallback, useEffect, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { BigNumber } from 'bignumber.js'
import { useUpdateSolanaPublicKey, useSolanaPublicKey } from 'state/solana/hooks'
import { isObject, localStore } from 'utils/tools'
import { Connection, PublicKey, LAMPORTS_PER_SOL } from '@solana/web3.js'

export function useConnect(): any {
  const updateSolanaPublicKey = useUpdateSolanaPublicKey()
  return useCallback(
    async (wallet: string, account: string) => {
      const { okxwallet, phantom } = window
      const ua = navigator.userAgent
      const isOKApp = /OKApp/i.test(ua)
      try {
        if (isMobile && !isOKApp && wallet === 'okx') {
          const encodedUrl =
            'https://www.okx.com/download?deeplink=' +
            encodeURIComponent('okx://wallet/dapp/url?dappUrl=' + encodeURIComponent(location.href))
          window.location.href = encodedUrl
          return
        }
        if ((!okxwallet && wallet === 'okx') || (!phantom && wallet === 'phantom')) {
          return
        }
        let publicKey = undefined
        let res = undefined
        // let signMessage: any = {}
        // let publicKey = ''
        // let signature = ''
        // const message = `Welcome to Bakery!`
        // const encodedMessage = new TextEncoder().encode(message)
        switch (wallet) {
          case 'okx':
            res = await okxwallet.solana.connect()
            publicKey = res.publicKey
            // signMessage = await okxwallet.solana.signMessage(encodedMessage, 'utf8')
            break
          case 'phantom':
            res = await phantom.solana.connect()
            publicKey = res.publicKey
            // publicKey = res.publicKey.toString()
            // signMessage = await phantom.solana.signMessage(encodedMessage, 'utf8')
            break
          default:
            break
        }
        updateSolanaPublicKey(publicKey.toString())
        const solanaWallet = localStore.get('Bakeryswap_solana_wallet')

        localStore.set('Bakeryswap_solana_wallet', {
          ...(isObject(solanaWallet) ? solanaWallet : {}),
          [account as string]: wallet,
        })
        return [publicKey]
      } catch (error) {
        console.log(error)
        return [undefined, undefined]
      }
    },
    [updateSolanaPublicKey]
  )
}

export function useSolBalance() {
  const [balance, setBalance] = useState(new BigNumber(0))
  const userPublicKey = useSolanaPublicKey()
  // todo
  const connection = useMemo(() => {
    return new Connection('https://solana-mainnet.g.alchemy.com/v2/FHs5QTpJVj68xaVxHBQzvOBA7IGkmVKa')
  }, [])

  const getBalance = useCallback(async () => {
    if (userPublicKey) {
      try {
        const res = await connection.getBalance(new PublicKey(userPublicKey), 'confirmed')
        if (res) {
          setBalance(new BigNumber(res).div(LAMPORTS_PER_SOL))
        } else {
          setBalance(new BigNumber(0))
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setBalance(new BigNumber(0))
    }
  }, [connection, userPublicKey])

  useEffect(() => {
    if (userPublicKey) {
      getBalance()
    }
    const refreshInterval = setInterval(getBalance, 3000)
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPublicKey])

  return balance
}

export function useSolTokenBalance() {
  const [balance, setBalance] = useState(new BigNumber(-1))
  const userPublicKey = useSolanaPublicKey()
  // todo
  const connection = useMemo(() => {
    return new Connection('https://solana-mainnet.g.alchemy.com/v2/FHs5QTpJVj68xaVxHBQzvOBA7IGkmVKa')
  }, [])

  const getBalance = useCallback(async () => {
    if (userPublicKey) {
      try {
        const { value } = await connection.getTokenAccountsByOwner(new PublicKey(userPublicKey), {
          mint: new PublicKey('GbroKgDk9PqkE7tTtmVWrt9qeDvimJCXqGTnpiKEryux'),
        })
        if (value.length) {
          const res = await connection.getTokenAccountBalance(value[0].pubkey)
          setBalance(new BigNumber(res.value.amount.toString()))
        } else {
          setBalance(new BigNumber(-1))
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      setBalance(new BigNumber(-1))
    }
  }, [connection, userPublicKey])

  useEffect(() => {
    if (userPublicKey) {
      getBalance()
    }
    const refreshInterval = setInterval(getBalance, 10000)
    return () => clearInterval(refreshInterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPublicKey])

  return balance
}
