import { useCallback, useEffect, useState } from 'react'

// hooks
import useYam from '../bakery/useYam'
import { useActiveWeb3React } from '../../hooks'

// sushi
import {
  getOneCatAddress,
  getDoggyCoinAddress,
  getPunkxAddress,
  getWethAddress,
  getSmallIdoETHAmount,
  getSmallIdoAmount,
  getBusdAddress,
  getUsdtAddress,
  getBktAddress,
  getBakeryAddress,
  getDoggyBakeBaseDropContract,
  getOneCatDropContract,
  getDoggyCoinDropContract,
  getBitLandDropContract,
  getBitCatDropContract,
  getBendDaoDropContract,
  getCatNFTContract,
  getOpenSkyContract,
  getBitcoinVMContract,
} from '../../sushi/utils'

import { IDOTYPE } from '../../constants/ido'
// import { CONTRACT_ADDRESSES } from '../../constants/pet'

const useAllowanceMax = (tokenName: string, type?: string) => {
  const { account } = useActiveWeb3React()
  const sushi = useYam()
  const [allowanceNum, setAllowanceNum] = useState(0)

  let tokenContract = getDoggyBakeBaseDropContract(sushi)
  switch (type) {
    case IDOTYPE.DOGGY:
      tokenContract = getDoggyBakeBaseDropContract(sushi)
      break
    case IDOTYPE.ONECAT:
      tokenContract = getOneCatDropContract(sushi)
      break
    case IDOTYPE.DOGGYCOIN:
      tokenContract = getDoggyCoinDropContract(sushi)
      break
    case IDOTYPE.BITLAND:
      tokenContract = getBitLandDropContract(sushi)
      break
    case IDOTYPE.BITCAT:
      tokenContract = getBitCatDropContract(sushi)
      break
    case IDOTYPE.BENDDAO:
      tokenContract = getBendDaoDropContract(sushi)
      break
    case IDOTYPE.CATNFT:
      tokenContract = getCatNFTContract(sushi)
      break
    case IDOTYPE.OPENSKY:
      tokenContract = getOpenSkyContract(sushi)
      break
    case IDOTYPE.BITCOINVM:
      tokenContract = getBitcoinVMContract(sushi)
      break
  }

  let tokenAddress = getBakeryAddress(sushi)
  switch (tokenName) {
    case 'busd':
      tokenAddress = getBusdAddress(sushi)
      break
    case 'usdt':
      tokenAddress = getUsdtAddress(sushi)
      break
    case 'bakery':
      tokenAddress = getBakeryAddress(sushi)
      break
    case 'bkt':
      tokenAddress = getBktAddress(sushi)
      break
    case 'eth':
      tokenAddress = getWethAddress(sushi)
      break
    case 'doggycoin':
      tokenAddress = getDoggyCoinAddress(sushi)
      break
    case 'punkx':
      tokenAddress = getPunkxAddress(sushi)
      break
    case 'onecat':
      tokenAddress = getOneCatAddress(sushi)
      break
  }

  const idoSmallNum = useCallback(async () => {
    if (tokenName && account) {
      try {
        const num =
          tokenName === 'eth'
            ? await getSmallIdoETHAmount(tokenContract, sushi)
            : await getSmallIdoAmount(tokenContract, tokenAddress)
        setAllowanceNum(num[2])
      } catch (e) {
        console.log('useIdoPet', e)
      }
    }
  }, [tokenName, account, tokenContract, sushi, tokenAddress])

  useEffect(() => {
    if (account && tokenName) {
      idoSmallNum()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenName, tokenContract])

  return allowanceNum
}

export default useAllowanceMax
