import { useCallback, useEffect, useState, useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { provider } from 'web3-core'

// utils
import { getAllowance, getContract, getAllowanceNft } from '../../utils/bakery/erc20'
import {
  getBktContract,
  getBusdContract,
  getUsdtContract,
  getBakeryContract,
  getDoggyContract,
  getDoggyNFTContract,
  getDoggyBakeBaseDropAddress,
  getToken2NftBaseAddress,
  getSwapNFTAddress,
  getOneCatDropAddress,
  getPunkxContract,
  getDoggyCoinContract,
  getWethContract,
  getDoggyCoinDropAddress,
  getOneCatContract,
  getBitLandDropAddress,
  getBitCatDropAddress,
  getBendDaoDropAddress,
  getCatNFTAddress,
  getOpenSkyAddress,
  getBitcoinVMAddress,
  getWBTCContract,
  getIQ50Address,
  getBakeryDropAddress,
} from '../../sushi/utils'

import { IDOTYPE } from '../../constants/ido'
import { IDTOKEN } from '../../constants/pet'

// hooks
import useYam from '../bakery/useYam'
import { useActiveWeb3React } from '..'
import useInterval from '../useInterval'

const useAllowanceLaunch = (tokenName: string, type?: string) => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account, library, chainId } = useActiveWeb3React()
  const ethereum = library?.provider
  const sushi = useYam()

  let lpTokenAddress = getDoggyBakeBaseDropAddress(sushi)
  switch (type) {
    case IDOTYPE.DOGGY:
      lpTokenAddress = getDoggyBakeBaseDropAddress(sushi)
      break
    case IDOTYPE.QUICKDOGGY:
      lpTokenAddress = getToken2NftBaseAddress(sushi)
      break
    case IDOTYPE.MINTDOGGY:
      lpTokenAddress = getToken2NftBaseAddress(sushi)
      break
    case IDOTYPE.DOGGYNFT:
      lpTokenAddress = getSwapNFTAddress(sushi)
      break
    case IDOTYPE.DOGGYMINTNFT:
      lpTokenAddress = getToken2NftBaseAddress(sushi)
      break
    case IDOTYPE.ONECAT:
      lpTokenAddress = getOneCatDropAddress(sushi)
      break
    case IDOTYPE.DOGGYCOIN:
      lpTokenAddress = getDoggyCoinDropAddress(sushi)
      break
    case IDOTYPE.BITLAND:
      lpTokenAddress = getBitLandDropAddress(sushi)
      break
    case IDOTYPE.BITCAT:
      lpTokenAddress = getBitCatDropAddress(sushi)
      break
    case IDOTYPE.BENDDAO:
      lpTokenAddress = getBendDaoDropAddress(sushi)
      break
    case IDOTYPE.CATNFT:
      lpTokenAddress = getCatNFTAddress(sushi)
      break
    case IDOTYPE.OPENSKY:
      lpTokenAddress = getOpenSkyAddress(sushi)
      break
    case IDOTYPE.BITCOINVM:
      lpTokenAddress = getBitcoinVMAddress(sushi)
      break
    case IDOTYPE.IQ50:
      lpTokenAddress = getIQ50Address(sushi)
      break
    case IDOTYPE.BAKERY:
      lpTokenAddress = getBakeryDropAddress(sushi)
      break
  }

  const lpContract = useMemo(() => {
    return getContract(ethereum as provider, lpTokenAddress)
  }, [ethereum, lpTokenAddress])

  let contract = getBakeryContract(sushi)
  switch (tokenName) {
    case 'busd':
      contract = getBusdContract(sushi)
      break
    case 'usdt':
      contract = getUsdtContract(sushi)
      break
    case 'bakery':
      contract = getBakeryContract(sushi)
      break
    case 'bkt':
      contract = getBktContract(sushi)
      break
    case 'doggy':
      contract = getDoggyContract(sushi)
      break
    case 'doggynft':
      contract = getDoggyNFTContract(sushi)
      break
    case 'eth':
      contract = getWethContract(sushi)
      break
    case 'doggycoin':
      contract = getDoggyCoinContract(sushi)
      break
    case 'punkx':
      contract = getPunkxContract(sushi)
      break
    case 'onecat':
      contract = getOneCatContract(sushi)
      break
    case 'wbtc':
      contract = getWBTCContract(sushi)
      break
  }

  const fetchAllowance = useCallback(async () => {
    if (tokenName && account) {
      if (tokenName !== 'eth') {
        try {
          const allowance = [IDOTYPE.DOGGYNFT, IDOTYPE.DOGGYMINTNFT].includes(type as any)
            ? await getAllowanceNft(contract, lpContract as any, account)
            : await getAllowance(contract, lpContract as any, account)
          setAllowance(
            [IDOTYPE.DOGGYNFT, IDOTYPE.DOGGYMINTNFT].includes(type as any)
              ? allowance
                ? new BigNumber(1)
                : new BigNumber(0)
              : new BigNumber(allowance)
          )
        } catch (error) {
          console.log('useIdoPet', error)
        }
      } else {
        setAllowance(new BigNumber(1))
      }
    }
  }, [account, contract, lpContract, tokenName, type])

  useEffect(() => {
    if (account && contract && lpContract) {
      fetchAllowance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, contract, lpContract])

  useInterval(fetchAllowance, !IDTOKEN[tokenName].includes(chainId) ? null : !allowance.toNumber() ? 1000 : 10000)

  return allowance
}

export default useAllowanceLaunch
