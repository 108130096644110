export type Claim = {
  version: '0.1.0'
  name: 'claim'
  instructions: [
    {
      name: 'verification'
      accounts: [
        {
          name: 'distributor'
          isMut: true
          isSigner: false
        },
        {
          name: 'from'
          isMut: true
          isSigner: false
          docs: ['Distributor ATA containing the tokens to distribute.']
        },
        {
          name: 'toAta'
          isMut: true
          isSigner: false
          docs: ['Account to send the claimed tokens to.']
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['Who is claiming the tokens.']
        },
        {
          name: 'claimStatus'
          isMut: true
          isSigner: false
          docs: ['Status of the claim.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        },
        {
          name: 'tokenProgram'
          isMut: false
          isSigner: false
          docs: ['SPL [Token] program.']
        }
      ]
      args: [
        {
          name: 'claimId'
          type: 'u64'
        },
        {
          name: 'amount'
          type: 'u64'
        },
        {
          name: 'signature'
          type: {
            array: ['u8', 64]
          }
        },
        {
          name: 'recoveryId'
          type: 'u8'
        }
      ]
    },
    {
      name: 'createDistributor'
      accounts: [
        {
          name: 'base'
          isMut: false
          isSigner: true
        },
        {
          name: 'payer'
          isMut: true
          isSigner: true
          docs: ['Payer to create the distributor.']
        },
        {
          name: 'distributor'
          isMut: true
          isSigner: false
          docs: ['[Distributor].']
        },
        {
          name: 'mint'
          isMut: false
          isSigner: false
          docs: ['The mint to distribute.']
        },
        {
          name: 'systemProgram'
          isMut: false
          isSigner: false
          docs: ['The [System] program.']
        }
      ]
      args: [
        {
          name: 'signer'
          type: {
            array: ['u8', 32]
          }
        }
      ]
    },
    {
      name: 'setDistributor'
      accounts: [
        {
          name: 'payer'
          isMut: true
          isSigner: true
        },
        {
          name: 'distributor'
          isMut: true
          isSigner: false
          docs: ['[Distributor].']
        }
      ]
      args: [
        {
          name: 'signer'
          type: {
            array: ['u8', 32]
          }
        }
      ]
    }
  ]
  accounts: [
    {
      name: 'distributor'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'base'
            docs: ['Base key used to generate the PDA.']
            type: 'publicKey'
          },
          {
            name: 'bump'
            docs: ['Bump seed.']
            type: 'u8'
          },
          {
            name: 'mint'
            docs: ['[Mint] of the token to be distributed.']
            type: 'publicKey'
          },
          {
            name: 'signer'
            docs: ['Public key of the distributor.']
            type: {
              array: ['u8', 32]
            }
          }
        ]
      }
    },
    {
      name: 'claimStatus'
      type: {
        kind: 'struct'
        fields: [
          {
            name: 'isClaimed'
            docs: ['If true, the tokens have been claimed.']
            type: 'u8'
          },
          {
            name: 'claimant'
            docs: ['Authority that claimed the tokens.']
            type: 'publicKey'
          },
          {
            name: 'claimedAt'
            docs: ['When the tokens were claimed.']
            type: 'i64'
          },
          {
            name: 'amount'
            docs: ['Amount of tokens claimed.']
            type: 'u64'
          }
        ]
      }
    }
  ]
  types: [
    {
      name: 'ErrorCode'
      type: {
        kind: 'enum'
        variants: [
          {
            name: 'OwnerMismatch'
          }
        ]
      }
    }
  ]
  events: [
    {
      name: 'ClaimedEvent'
      fields: [
        {
          name: 'claimId'
          type: 'u64'
          index: false
        },
        {
          name: 'claimant'
          type: 'publicKey'
          index: false
        },
        {
          name: 'amount'
          type: 'u64'
          index: false
        }
      ]
    }
  ]
  errors: [
    {
      code: 6000
      name: 'InvalidSignature'
    },
    {
      code: 6001
      name: 'InvalidSigner'
    },
    {
      code: 6002
      name: 'WithoutPermission'
    }
  ]
}

export const IDL: Claim = {
  version: '0.1.0',
  name: 'claim',
  instructions: [
    {
      name: 'verification',
      accounts: [
        {
          name: 'distributor',
          isMut: true,
          isSigner: false,
        },
        {
          name: 'from',
          isMut: true,
          isSigner: false,
          docs: ['Distributor ATA containing the tokens to distribute.'],
        },
        {
          name: 'toAta',
          isMut: true,
          isSigner: false,
          docs: ['Account to send the claimed tokens to.'],
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['Who is claiming the tokens.'],
        },
        {
          name: 'claimStatus',
          isMut: true,
          isSigner: false,
          docs: ['Status of the claim.'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.'],
        },
        {
          name: 'tokenProgram',
          isMut: false,
          isSigner: false,
          docs: ['SPL [Token] program.'],
        },
      ],
      args: [
        {
          name: 'claimId',
          type: 'u64',
        },
        {
          name: 'amount',
          type: 'u64',
        },
        {
          name: 'signature',
          type: {
            array: ['u8', 64],
          },
        },
        {
          name: 'recoveryId',
          type: 'u8',
        },
      ],
    },
    {
      name: 'createDistributor',
      accounts: [
        {
          name: 'base',
          isMut: false,
          isSigner: true,
        },
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
          docs: ['Payer to create the distributor.'],
        },
        {
          name: 'distributor',
          isMut: true,
          isSigner: false,
          docs: ['[Distributor].'],
        },
        {
          name: 'mint',
          isMut: false,
          isSigner: false,
          docs: ['The mint to distribute.'],
        },
        {
          name: 'systemProgram',
          isMut: false,
          isSigner: false,
          docs: ['The [System] program.'],
        },
      ],
      args: [
        {
          name: 'signer',
          type: {
            array: ['u8', 32],
          },
        },
      ],
    },
    {
      name: 'setDistributor',
      accounts: [
        {
          name: 'payer',
          isMut: true,
          isSigner: true,
        },
        {
          name: 'distributor',
          isMut: true,
          isSigner: false,
          docs: ['[Distributor].'],
        },
      ],
      args: [
        {
          name: 'signer',
          type: {
            array: ['u8', 32],
          },
        },
      ],
    },
  ],
  accounts: [
    {
      name: 'distributor',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'base',
            docs: ['Base key used to generate the PDA.'],
            type: 'publicKey',
          },
          {
            name: 'bump',
            docs: ['Bump seed.'],
            type: 'u8',
          },
          {
            name: 'mint',
            docs: ['[Mint] of the token to be distributed.'],
            type: 'publicKey',
          },
          {
            name: 'signer',
            docs: ['Public key of the distributor.'],
            type: {
              array: ['u8', 32],
            },
          },
        ],
      },
    },
    {
      name: 'claimStatus',
      type: {
        kind: 'struct',
        fields: [
          {
            name: 'isClaimed',
            docs: ['If true, the tokens have been claimed.'],
            type: 'u8',
          },
          {
            name: 'claimant',
            docs: ['Authority that claimed the tokens.'],
            type: 'publicKey',
          },
          {
            name: 'claimedAt',
            docs: ['When the tokens were claimed.'],
            type: 'i64',
          },
          {
            name: 'amount',
            docs: ['Amount of tokens claimed.'],
            type: 'u64',
          },
        ],
      },
    },
  ],
  types: [
    {
      name: 'ErrorCode',
      type: {
        kind: 'enum',
        variants: [
          {
            name: 'OwnerMismatch',
          },
        ],
      },
    },
  ],
  events: [
    {
      name: 'ClaimedEvent',
      fields: [
        {
          name: 'claimId',
          type: 'u64',
          index: false,
        },
        {
          name: 'claimant',
          type: 'publicKey',
          index: false,
        },
        {
          name: 'amount',
          type: 'u64',
          index: false,
        },
      ],
    },
  ],
  errors: [
    {
      code: 6000,
      name: 'InvalidSignature',
    },
    {
      code: 6001,
      name: 'InvalidSigner',
    },
    {
      code: 6002,
      name: 'WithoutPermission',
    },
  ],
}
