import { useCallback, useEffect, useState } from 'react'
import BigNumber from 'bignumber.js'

// utils
import { CONTRACT_ADDRESSES } from 'constants/pet'
import ERC20_ABI from 'utils/lib/abi/erc20.json'

// hooks
import { useActiveWeb3React } from '.'
import useInterval from './useInterval'
import { getWeb3 } from 'utils/web3'

const useAllowance = () => {
  const [allowance, setAllowance] = useState(new BigNumber(0))
  const { account, chainId } = useActiveWeb3React()
  const web3 = getWeb3(chainId as number)

  const fetchAllowance = useCallback(async () => {
    if (account) {
      const contract = new web3.eth.Contract(
        JSON.parse(JSON.stringify(ERC20_ABI)),
        CONTRACT_ADDRESSES.dogex[chainId as number]
      )
      try {
        const allowance: any =
          (await contract.methods.allowance(account, CONTRACT_ADDRESSES.deposit[chainId as number]).call()) || 0
        setAllowance(new BigNumber(allowance))
      } catch (error) {
        console.log('allowance', error)
      }
    }
  }, [account, chainId, web3])

  useEffect(() => {
    if (account) {
      fetchAllowance()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  useInterval(fetchAllowance, 10000)

  return allowance
}

export default useAllowance
