import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
// import { CONTRACT_ADDRESSES } from 'constants/pet'

// import { FACTORY_PUNXE_IDO } from '../constants/pet'

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const getBakeryDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bakeryDrop
}

export const getBakeryDropAddress = (sushi) => {
  return sushi && sushi.contracts.bakeryDrop && sushi.contracts.bakeryDrop.options.address
}

export const getIQ50Contract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.iq50Stake
}

export const getIQ50Address = (sushi) => {
  return sushi && sushi.contracts.iq50Stake && sushi.contracts.iq50Stake.options.address
}

export const getWBTCContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.wBTCToken
}
export const getBitcoinVMAddress = (sushi) => {
  return sushi && sushi.contracts.bitcoinVMDrop && sushi.contracts.bitcoinVMDrop.options.address
}
export const getBitcoinVMContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bitcoinVMDrop
}
export const getOpenSkyAddress = (sushi) => {
  return sushi && sushi.contracts.openSkyDrop && sushi.contracts.openSkyDrop.options.address
}
export const getOpenSkyContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.openSkyDrop
}
export const getCatNFTAddress = (sushi) => {
  return sushi && sushi.contracts.catNFTDrop && sushi.contracts.catNFTDrop.options.address
}
export const getCatNFTContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.catNFTDrop
}
export const getBendDaoDropAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bendDaoDrop && sushi.contracts.bendDaoDrop.options.address
}
export const getBendDaoDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bendDaoDrop
}
export const getBitCatDropAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bitCatDrop && sushi.contracts.bitCatDrop.options.address
}
export const getBitCatDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bitCatDrop
}
export const getOneCatContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.oneCatToken
}
export const getOneCatAddress = (sushi) => {
  return sushi && sushi.oneCatAddress
}
export const getBitLandDropAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bitLandDrop && sushi.contracts.bitLandDrop.options.address
}
export const getBitLandDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bitLandDrop
}
export const getDoggyCoinContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyCoin
}
export const getDoggyCoinAddress = (sushi) => {
  return sushi && sushi.doggyCoinAddress
}
export const getWethAddress = (sushi) => {
  return sushi && sushi.wethAddress
}
export const getPunkxAddress = (sushi) => {
  return sushi && sushi.punkxAddress
}
export const getPunkxContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.punkxToken
}
export const getOneCatDropAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.oneCatDrop && sushi.contracts.oneCatDrop.options.address
}
export const getOneCatDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.oneCatDrop
}
export const getDoggyCoinDropAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyCoinDrop && sushi.contracts.doggyCoinDrop.options.address
}
export const getDoggyCoinDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyCoinDrop
}
export const getMasterChefAddress = (sushi) => {
  return sushi && sushi.masterChefAddress
}
export const getSushiAddress = (sushi) => {
  return sushi && sushi.sushiAddress
}
export const getWethContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.weth
}
export const getMasterChefContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.masterChef
}
export const getSushiContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.sushi
}
export const getDoggyBakeBaseDropContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyBakeBaseDrop
}
export const getToken2NftBaseContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.token2NftBaseDrop
}
export const getSwapNFTContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.swapNFT
}
export const getBusdAddress = (sushi) => {
  return sushi && sushi.busdAddress
}
export const getUsdtAddress = (sushi) => {
  return sushi && sushi.usdtAddress
}
export const getBktAddress = (sushi) => {
  return sushi && sushi.bktAddress
}
export const getBakeryAddress = (sushi) => {
  return sushi && sushi.bakeryAddress
}
export const getDoggyAddress = (sushi) => {
  return sushi && sushi.doggyAddress
}
export const getBusdContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.busdToken
}
export const getUsdtContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.usdtToken
}
export const getBktContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bktToken
}
export const getBakeryContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.bakeryToken
}
export const getDoggyContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyToken
}
export const getDoggyNFTContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.doggyNFT
}
export const getDoggyBakeBaseDropAddress = (sushi) => {
  return (
    sushi && sushi.contracts && sushi.contracts.doggyBakeBaseDrop && sushi.contracts.doggyBakeBaseDrop.options.address
  )
}
export const getToken2NftBaseAddress = (sushi) => {
  return (
    sushi && sushi.contracts && sushi.contracts.token2NftBaseDrop && sushi.contracts.token2NftBaseDrop.options.address
  )
}
export const getSwapNFTAddress = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.swapNFT && sushi.contracts.swapNFT.options.address
}
export const getDishMasterContract = (sushi) => {
  return sushi && sushi.contracts && sushi.contracts.dishMaster
}
export const getTransferAddress = (sushi) => {
  return sushi && sushi.transferAddress
}
export const getWeb3 = (sushi) => {
  return sushi && sushi.web3
}
export const token2NftMint = async (token2NftMintContract, account, amount, nfts, action = 'buy') => {
  return action === 'buy'
    ? token2NftMintContract.methods
        .token2Nft(amount, nfts)
        .send({ from: account })
        .on('transactionHash', (tx) => {
          console.log(tx)
          return tx.transactionHash
        })
    : token2NftMintContract.methods
        .nft2Token(nfts)
        .send({ from: account })
        .on('transactionHash', (tx) => {
          console.log(tx)
          return tx.transactionHash
        })
}
export const swapNft2Token = async (swapNft2TokenContract, account, amount, nfts, value, time, action = 'buy') => {
  return action === 'buy'
    ? swapNft2TokenContract.methods
        .swapETH2NFT(amount, nfts, time)
        .send({ from: account, value: value })
        .on('transactionHash', (tx) => {
          console.log(tx)
          return tx.transactionHash
        })
    : swapNft2TokenContract.methods
        .swapNft2ETH(value, nfts, time)
        .send({ from: account })
        .on('transactionHash', (tx) => {
          console.log(tx)
          return tx.transactionHash
        })
}
export const bakeBaseIDODepositToken = async (bakeBaseIDOContract, account, token, amount) => {
  return bakeBaseIDOContract.methods
    .deposit(token, amount)
    .send({ from: account })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}

export const bakeBaseIDODepositBNB = async (bakeBaseIDOContract, account, amount) => {
  return bakeBaseIDOContract.methods
    .deposit('0x0000000000000000000000000000000000000000', 0)
    .send({ from: account, value: amount })
    .on('transactionHash', (tx) => {
      console.log(tx)
      return tx.transactionHash
    })
}
export async function getSmallIdoAmount(bakeBaseIDOContract, token) {
  if (!bakeBaseIDOContract?._address) return 0
  try {
    return await bakeBaseIDOContract.methods.tokenInfoMap(token).call()
  } catch (e) {
    console.error(e)
    return 0
  }
}

export async function getRemainDropAmount(bakeBaseIDOContract, account, token) {
  if (!bakeBaseIDOContract?._address) return 0
  try {
    return await bakeBaseIDOContract.methods.getRemainDropAmount(token, account).call()
  } catch (e) {
    console.error(e)
    return 0
  }
}

export async function getSmallIdoETHAmount(bakeBaseIDOContract, sushi) {
  if (!bakeBaseIDOContract?._address) return 0
  try {
    return await bakeBaseIDOContract.methods.tokenInfoMap(sushi.wethAddress).call()
  } catch (e) {
    console.error(e)
    return 0
  }
}

export async function getRemainIdoETHAmount(bakeBaseIDOContract, account, sushi) {
  if (!bakeBaseIDOContract?._address) return 0
  try {
    return await bakeBaseIDOContract.methods.getRemainDropAmount(sushi.wethAddress, account).call()
  } catch (e) {
    console.error(e)
    return 0
  }
}

export async function getIdoStartBlock(bakeBaseIDOContract) {
  return await bakeBaseIDOContract.methods.startBlock().call()
}

export async function getIdoEndBlock(bakeBaseIDOContract) {
  return await bakeBaseIDOContract.methods.endBlock().call()
}

export const transferErc20 = async (tokenContract, from, to, amount) => {
  return tokenContract.methods.transfer(to, amount).send({ from })
}
export const transferOther = async (web3, tokenContract, from, to, tokenAddress, amount, chainId) => {
  const { ethereum } = window
  const data = tokenContract.methods.transfer(to, web3.utils.toWei(amount.toString(), 'ether')).encodeABI()
  const transferParam = {
    to: tokenAddress,
    from: from,
    data: data,
    chainId: web3.utils.toHex(chainId),
  }
  return ethereum.request({ method: 'eth_sendTransaction', params: [transferParam] })
}
export const transferEth = async (web3, from, to, amount) => {
  return web3.eth.sendTransaction({ from, to, value: amount })
}

export const approve = async (lpContract, masterChefContract, account) => {
  return lpContract.methods
    .approve(masterChefContract.options.address, ethers.constants.MaxUint256)
    .send({ from: account })
}

export const approveNftAll = async (lpContract, masterChefContract, account) => {
  return lpContract.methods.setApprovalForAll(masterChefContract.options.address, true).send({ from: account })
}

/**
 *
 * @param masterContract
 * @returns {Promise<{
      [poolAddress: string]: : {
        bethRoi: BigNumber
        bakeRoi: BigNumber
        rewardStartBlock: BigNumber
        rewardEndBlock: BigNumber
        amount: BigNumber
        bethValueRoi: BigNumber
        bakeValueRoi: BigNumber
      }
  }>}
 */
export const redeem = async (masterChefContract, account) => {
  const now = new Date().getTime() / 1000
  if (now >= 1597172400) {
    return masterChefContract.methods
      .exit()
      .send({ from: account })
      .on('transactionHash', (tx) => {
        console.log(tx)
        return tx.transactionHash
      })
  } else {
    alert('pool not active')
  }
}
