import { PublicKey } from '@solana/web3.js'
import BN from 'bn.js'

export const findClaimStatusKey = async (
  index: BN,
  distributor: PublicKey,
  PROGRAM_ID: PublicKey
): Promise<[PublicKey, number]> => {
  return PublicKey.findProgramAddressSync(
    [Buffer.from('ClaimStatus'), index.toArrayLike(Buffer, 'le', 8), distributor.toBuffer()],
    PROGRAM_ID
  )
}
